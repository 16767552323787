import React, { useEffect, useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button,
    Box,
    Pagination,
    Paper,
    Typography,
    Dialog,
    DialogTitle,
    DialogActions,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_BACKEND_URL || 'https://api.admin.marsreel.com';

const StyledTableContainer = styled(TableContainer)({
    backgroundColor: '#202020',
    borderRadius: '12px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
    overflowX: 'auto',
});

const TableHeaderCell = styled(TableCell)({
    fontSize: '1rem',
    fontWeight: 'bold',
    color: '#12c099',
    backgroundColor: '#1c1c1c',
    padding: '16px',
});

const TableRowStyled = styled(TableRow)({
    '&:nth-of-type(even)': { backgroundColor: '#282828' },
    '&:hover': { backgroundColor: '#333' },
});

const TableBodyCell = styled(TableCell)({
    color: '#fff',
    fontSize: '0.9rem',
    padding: '16px',
});

const ActionContainer = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start', // Align items to the left
    flexWrap: 'wrap', // Ensures wrapping on smaller screens
    gap: '8px', // Space between buttons
});

const StyledButton = styled(Button)({
    minWidth: '90px', // Ensures uniform button width
    textTransform: 'none',
});

const PaginationBox = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
});

const ApplicantsContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    padding: '1rem',
    backgroundColor: '#1c1c1c',
    minHeight: '100vh',
});

const Title = styled(Typography)({
    fontSize: '1.8rem',
    color: '#fff',
    fontWeight: 'bold',
    marginBottom: '20px',
});

const Applicants = () => {
    const [applicants, setApplicants] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [selectedApplicantId, setSelectedApplicantId] = useState(null);

    useEffect(() => {
        fetchApplicants();
    }, [page]);

    const fetchApplicants = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/api/applicants?page=${page}&limit=10`);
            setApplicants(response.data.applicants);
            setTotalPages(response.data.totalPages);
        } catch (error) {
            console.error('Error fetching applicants:', error);
        }
    };

    const handleAction = async (id, status) => {
        try {
            await axios.put(`${API_BASE_URL}/api/applicants/${id}/update-status`, { status });
            fetchApplicants(); // Refresh list after action

            alert(`Applicant status updated to ${status}`);
        } catch (error) {
            console.error(`Error updating status of applicant ${id}:`, error);
        }
    };


    const openDeleteDialog = (id) => {
        setSelectedApplicantId(id);
        setDeleteDialogOpen(true);
    };

    const closeDeleteDialog = () => {
        setDeleteDialogOpen(false);
        setSelectedApplicantId(null);
    };

    const confirmDelete = async () => {
        try {
            await axios.delete(`${API_BASE_URL}/api/applicants/${selectedApplicantId}`);
            fetchApplicants(); // Refresh the list
            closeDeleteDialog();
        } catch (error) {
            console.error('Error deleting applicant:', error);
        }
    };

    return (
        <ApplicantsContainer>
            <Title>Applicants</Title>
            <StyledTableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableHeaderCell>Name</TableHeaderCell>
                            <TableHeaderCell>Email</TableHeaderCell>
                            <TableHeaderCell>Phone</TableHeaderCell>
                            <TableHeaderCell>City, State, Zip</TableHeaderCell>
                            <TableHeaderCell>Role</TableHeaderCell>
                            <TableHeaderCell>Date Applied</TableHeaderCell>
                            <TableHeaderCell>Actions</TableHeaderCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {applicants.map((applicant) => (
                            <TableRowStyled key={applicant._id}>
                                <TableBodyCell>{applicant.name}</TableBodyCell>
                                <TableBodyCell>{applicant.email}</TableBodyCell>
                                <TableBodyCell>{applicant.phone}</TableBodyCell>
                                <TableBodyCell>{`${applicant.city}, ${applicant.state}, ${applicant.zip}`}</TableBodyCell>
                                <TableBodyCell>{applicant.role}</TableBodyCell>
                                <TableBodyCell>{new Date(applicant.dateApplied).toLocaleDateString()}</TableBodyCell>
                                <TableBodyCell>
                                    <ActionContainer>
                                        <StyledButton
                                            onClick={() => handleAction(applicant._id, 'Approved')}
                                            color="success"
                                            variant="contained"
                                            size="small">
                                            Approve
                                        </StyledButton>
                                        <StyledButton
                                            onClick={() => handleAction(applicant._id, 'Interview')}
                                            color="primary"
                                            variant="contained"
                                            size="small">
                                            Interview
                                        </StyledButton>
                                        <StyledButton
                                            onClick={() => handleAction(applicant._id, 'Denied')}
                                            color="error"
                                            variant="contained"
                                            size="small">
                                            Deny
                                        </StyledButton>
                                        <StyledButton
                                            component={Link}
                                            to={`/applicants/${applicant._id}`}
                                            color="secondary"
                                            variant="contained"
                                            size="small">
                                            View
                                        </StyledButton>
                                        <StyledButton
                                            onClick={() => openDeleteDialog(applicant._id)}
                                            color="warning"
                                            variant="contained"
                                            size="small">
                                            Delete
                                        </StyledButton>
                                    </ActionContainer>
                                </TableBodyCell>
                            </TableRowStyled>
                        ))}
                    </TableBody>
                </Table>
            </StyledTableContainer>
            <PaginationBox>
                <Pagination count={totalPages} page={page} onChange={(e, value) => setPage(value)} />
            </PaginationBox>

            {/* Delete Confirmation Dialog */}
            <Dialog open={deleteDialogOpen} onClose={closeDeleteDialog}>
                <DialogTitle>Are you sure you want to delete this applicant?</DialogTitle>
                <DialogActions>
                    <Button onClick={closeDeleteDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={confirmDelete} color="secondary">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </ApplicantsContainer>
    );
};

export default Applicants;
