// Sidebar.js
import React from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, IconButton, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import {
    Home as HomeIcon,
    CalendarToday as CalendarIcon,
    Group as GroupIcon,
    Sports as SportsIcon,
    LocationOn as LocationOnIcon,
    BarChart as BarChartIcon,
    Map as MapIcon,
    Event as EventIcon,
    VideoLibrary as VideoLibraryIcon,
    PlaylistAddCheck as WaitlistIcon,
    ChevronLeft as ChevronLeftIcon,
    ChevronRight as ChevronRightIcon,
    ExitToApp as ExitToAppIcon,
    Store as StoreIcon,
    SupervisorAccount as SupervisorAccountIcon,
    Business as BusinessIcon,
    PeopleAlt as PeopleAltIcon,
    PersonAdd as ApplicantsIcon,
} from '@mui/icons-material';

const drawerWidth = 240;

const CustomDrawer = styled(Drawer)(({ open }) => ({
    width: open ? drawerWidth : 60,
    transition: 'width 0.3s',
    whiteSpace: 'nowrap',
    flexShrink: 0,
    '& .MuiDrawer-paper': {
        width: open ? drawerWidth : 60,
        transition: 'width 0.3s',
        overflowX: 'hidden',
        backgroundColor: '#202020',
        color: '#fff',
    },
}));

const CustomListItemIcon = styled(ListItemIcon)({
    color: '#12c099',
});

const CustomListItemText = styled(ListItemText)(({ open }) => ({
    fontSize: '0.8rem',
    opacity: open ? 1 : 0,
}));

const Logo = styled('img')(({ open }) => ({
    height: 'auto',
    width: 'auto',
    maxWidth: '100px',
    minWidth: '50px',
    cursor: 'pointer',
    margin: '10px auto',
    display: open ? 'block' : 'none',
}));

const Sidebar = ({ open, handleDrawerToggle, handleLogout }) => {
    return (
        <CustomDrawer variant="permanent" open={open}>
            <IconButton onClick={handleDrawerToggle} sx={{ color: '#12c099' }}>
                {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
            <Logo
                src="https://marsreel.com/wp-content/themes/marsreel/images/shared/marsreel-logo.png"
                alt="MarsReel"
                open={open}
            />
            <List sx={{ flexGrow: 1 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', mt: 2 }}>
                    <ListItem button component={Link} to="/dashboard">
                        <CustomListItemIcon>
                            <HomeIcon />
                        </CustomListItemIcon>
                        <CustomListItemText primary="Overview" open={open} />
                    </ListItem>
                    <ListItem button component={Link} to="/BookingDashboard">
                        <CustomListItemIcon>
                            <CalendarIcon />
                        </CustomListItemIcon>
                        <CustomListItemText primary="Dashboard" open={open} />
                    </ListItem>
                    {/* Leads link below Booking Dashboard */}
                    <ListItem button component={Link} to="/leads">
                        <CustomListItemIcon>
                            <PeopleAltIcon /> {/* Icon for Leads */}
                        </CustomListItemIcon>
                        <CustomListItemText primary="Leads" open={open} />
                    </ListItem>
                    <ListItem button component={Link} to="/waitlist">
                        <CustomListItemIcon>
                            <WaitlistIcon />
                        </CustomListItemIcon>
                        <CustomListItemText primary="Waitlist" open={open} />
                    </ListItem>
                    <ListItem button component={Link} to="/athletes">
                        <CustomListItemIcon>
                            <GroupIcon /> {/* Icon for Athletes */}
                        </CustomListItemIcon>
                        <CustomListItemText primary="Athletes" open={open} />
                    </ListItem>
                    <ListItem button component={Link} to="/coaches">
                        <CustomListItemIcon>
                            <SportsIcon /> {/* Icon for Coaches */}
                        </CustomListItemIcon>
                        <CustomListItemText primary="Coaches" open={open} />
                    </ListItem>
                    <ListItem button component={Link} to="/sitemanagers">
                        <CustomListItemIcon>
                            <SupervisorAccountIcon /> {/* Correct icon for Site Managers */}
                        </CustomListItemIcon>
                        <CustomListItemText primary="Site Managers" open={open} />
                    </ListItem>
                    <ListItem button component={Link} to="/employees">
                        <CustomListItemIcon>
                            <BusinessIcon /> {/* Corrected icon for Employees */}
                        </CustomListItemIcon>
                        <CustomListItemText primary="Employees" open={open} />
                    </ListItem>
                    <ListItem button component={Link} to="/applicants">
                        <CustomListItemIcon>
                            <ApplicantsIcon /> {/* Icon for Applicants */}
                        </CustomListItemIcon>
                        <CustomListItemText primary="Applicants" open={open} />
                    </ListItem>
                    <ListItem button component={Link} to="/markets">
                        <CustomListItemIcon>
                            <LocationOnIcon />
                        </CustomListItemIcon>
                        <CustomListItemText primary="Markets" open={open} />
                    </ListItem>
                    <ListItem button component={Link} to="/sessions">
                        <CustomListItemIcon>
                            <EventIcon />
                        </CustomListItemIcon>
                        <CustomListItemText primary="Sessions" open={open} />
                    </ListItem>
                    <ListItem button component={Link} to="/financials">
                        <CustomListItemIcon>
                            <BarChartIcon />
                        </CustomListItemIcon>
                        <CustomListItemText primary="Financials" open={open} />
                    </ListItem>
                    <ListItem button component={Link} to="/contentfeed">
                        <CustomListItemIcon>
                            <VideoLibraryIcon />
                        </CustomListItemIcon>
                        <CustomListItemText primary="Content Feed" open={open} />
                    </ListItem>
                </Box>
            </List>
            <List>
                <ListItem button onClick={handleLogout}>
                    <CustomListItemIcon>
                        <ExitToAppIcon />
                    </CustomListItemIcon>
                    <CustomListItemText primary="Logout" open={open} />
                </ListItem>
            </List>
        </CustomDrawer>
    );
};

export default Sidebar;
