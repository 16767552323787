import React, { useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { Box, Typography, Table, TableBody, TableCell, TableRow, Paper, Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Select, MenuItem, Chip, InputLabel, FormControl,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import moment from 'moment-timezone';


const API_BASE_URL = process.env.REACT_APP_BACKEND_URL || 'https://api.admin.marsreel.com';

const OverviewContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    padding: '2rem',
    backgroundColor: '#1c1c1c',
    minHeight: '100vh',
});

const Title = styled(Typography)({
    fontSize: '1.8rem',
    color: '#fff',
    fontWeight: 'bold',
    marginBottom: '20px',
});

const StyledTable = styled(Table)({
    backgroundColor: '#202020',
    borderRadius: '12px',
});

const TableBodyCell = styled(TableCell)({
    color: '#fff',
    fontSize: '1rem',
    padding: '16px',
});

const TableHeaderCell = styled(TableCell)({
    fontSize: '1rem',
    fontWeight: 'bold',
    color: '#12c099',
    backgroundColor: '#1c1c1c',
    padding: '16px',
});

const ApplicantOverview = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [applicant, setApplicant] = useState(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [editForm, setEditForm] = useState({
        name: '',
        email: '',
        phone: '',
        role: '',
        hourlyRate: '',
        city: '',
        state: '',
        zip: '',
        availability: {}, // ✅ Add availability
    });

    useEffect(() => {
        if (applicant) {
            setEditForm({
                name: applicant.name,
                email: applicant.email,
                phone: applicant.phone,
                role: applicant.role,
                hourlyRate: applicant.hourlyRate || '',
                city: applicant.city || '',
                state: applicant.state || '',
                zip: applicant.zip || '',
                availability: Object.fromEntries(
                    Object.entries(applicant.availability || {}).map(([day, times]) => [
                        day,
                        times.map(slot =>
                            `${moment.utc(slot.start, "HH:mm").tz(applicant.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone).format("h:mm A")} - 
                         ${moment.utc(slot.end, "HH:mm").tz(applicant.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone).format("h:mm A")}`
                        )
                    ])
                ),
                timezone: applicant.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone
            });
        }
    }, [applicant]);




    const handleEditChange = (e) => {
        setEditForm({ ...editForm, [e.target.name]: e.target.value });
    };

    const handleEditSubmit = async () => {
        try {
            const updatedData = {
                ...editForm,
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone, // ✅ Save user's time zone
            };

            await axios.put(`${API_BASE_URL}/api/applicants/${id}/update`, updatedData);
            setEditDialogOpen(false);
            fetchApplicant(); // Refresh data
        } catch (error) {
            console.error('Error updating applicant:', error);
        }
    };



    useEffect(() => {
        fetchApplicant();
    }, []);

    const fetchApplicant = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/api/applicants/${id}`);
            setApplicant(response.data);
        } catch (error) {
            console.error('Error fetching applicant:', error);
        }
    };

    const openDeleteDialog = () => {
        setDeleteDialogOpen(true);
    };

    const closeDeleteDialog = () => {
        setDeleteDialogOpen(false);
    };

    const confirmDelete = async () => {
        try {
            await axios.delete(`${API_BASE_URL}/api/applicants/${id}`);
            navigate('/applicants'); // Redirect to applicants list after deletion
        } catch (error) {
            console.error('Error deleting applicant:', error);
        }
    };

    if (!applicant) return <Typography color="white">Loading...</Typography>;

    const handleAvailabilityChange = (day, selectedTimes) => {
        const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        const utcTimes = selectedTimes.map(timeSlot => {
            const [start, end] = timeSlot.split(" - ");
            return {
                start: moment.tz(start.trim(), "h:mm A", userTimezone).utc().format("HH:mm"),
                end: moment.tz(end.trim(), "h:mm A", userTimezone).utc().format("HH:mm"),
                timezone: userTimezone
            };
        });

        setEditForm(prevForm => ({
            ...prevForm,
            availability: {
                ...prevForm.availability,
                [day]: utcTimes.length > 0 ? utcTimes : []
            },
            timezone: userTimezone
        }));
    };

    return (
        <OverviewContainer>
            <Title>{applicant.name}</Title>
            <Paper>
                <StyledTable>
                    <TableBody>
                        <TableRow>
                            <TableHeaderCell>Email</TableHeaderCell>
                            <TableBodyCell>{applicant.email}</TableBodyCell>
                        </TableRow>
                        <TableRow>
                            <TableHeaderCell>Phone</TableHeaderCell>
                            <TableBodyCell>{applicant.phone}</TableBodyCell>
                        </TableRow>
                        <TableRow>
                            <TableHeaderCell>Role</TableHeaderCell>
                            <TableBodyCell>{applicant.role}</TableBodyCell>
                        </TableRow>
                        <TableRow>
                            <TableHeaderCell>Hourly Rate</TableHeaderCell>
                            <TableBodyCell>${applicant.hourlyRate}/hr</TableBodyCell>
                        </TableRow>
                        <TableRow>
                            <TableHeaderCell>City, State, Zip</TableHeaderCell>
                            <TableBodyCell>{`${applicant.city}, ${applicant.state}, ${applicant.zip}`}</TableBodyCell>
                        </TableRow>
                        <TableRow>
                            <TableHeaderCell>Waiver Signed</TableHeaderCell>
                            <TableBodyCell>{applicant.waiverSigned ? 'Yes' : 'No'}</TableBodyCell>
                        </TableRow>
                        <TableRow>
                            <TableHeaderCell>Waiver URL</TableHeaderCell>
                            <TableBodyCell>
                                {applicant.waiverUrl ? (
                                    <a href={applicant.waiverUrl} target="_blank" rel="noopener noreferrer" style={{ color: '#12c099' }}>
                                        View Waiver
                                    </a>
                                ) : (
                                    'Not Available'
                                )}
                            </TableBodyCell>
                        </TableRow>
                        <TableRow>
                            <TableHeaderCell>Payment Method</TableHeaderCell>
                            <TableBodyCell>{applicant.paymentDetails?.join(', ') || 'Not Provided'}</TableBodyCell>
                        </TableRow>
                        <TableRow>
                            <TableHeaderCell>Experience</TableHeaderCell>
                            <TableBodyCell>{applicant.experience || 'Not Provided'}</TableBodyCell>
                        </TableRow>
                        <TableRow>
                            <TableHeaderCell>Education</TableHeaderCell>
                            <TableBodyCell>
                                {applicant.education?.college?.length > 0
                                    ? applicant.education.college.map((edu) => (
                                        <div key={edu.school}>{edu.school} - {edu.degree}</div>
                                    ))
                                    : 'Not Provided'}
                            </TableBodyCell>
                        </TableRow>
                        <TableRow>
                            <TableHeaderCell>Availability</TableHeaderCell>
                            <TableBodyCell>
                                <ul style={{ paddingLeft: '20px' }}>
                                    {Object.entries(applicant.availability || {}).map(([day, times]) => (
                                        <li key={day} style={{ listStyleType: 'none' }}>
                                            <strong style={{ color: '#12c099' }}>{day.charAt(0).toUpperCase() + day.slice(1)}:</strong> {times.length > 0 ? times.join(', ') : 'Not Available'}
                                        </li>
                                    ))}
                                </ul>
                            </TableBodyCell>
                        </TableRow>
                        <TableRow>
                            <TableHeaderCell>CPR Certified</TableHeaderCell>
                            <TableBodyCell>{applicant.cprCertified}</TableBodyCell>
                        </TableRow>
                        <TableRow>
                            <TableHeaderCell>Authorized to Work in US</TableHeaderCell>
                            <TableBodyCell>{applicant.authorizedToWorkInUS}</TableBodyCell>
                        </TableRow>
                        <TableRow>
                            <TableHeaderCell>Date Applied</TableHeaderCell>
                            <TableBodyCell>{new Date(applicant.dateApplied).toLocaleDateString()}</TableBodyCell>
                        </TableRow>
                    </TableBody>
                </StyledTable>
            </Paper>

            {/* Buttons */}
            <Box display="flex" justifyContent="space-between" mt={3}>
                <Button variant="contained" color="primary" href="/applicants">
                    Back to Applicants
                </Button>
                <Button variant="contained" color="primary" onClick={() => setEditDialogOpen(true)}>
                    Edit Applicant
                </Button>
                <Button variant="contained" color="error" onClick={openDeleteDialog}>
                    Delete Applicant
                </Button>
            </Box>

            {/* Delete Confirmation Dialog */}
            <Dialog open={deleteDialogOpen} onClose={closeDeleteDialog}>
                <DialogTitle>Are you sure you want to delete this applicant?</DialogTitle>
                <DialogActions>
                    <Button onClick={closeDeleteDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={confirmDelete} color="secondary">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={editDialogOpen} onClose={() => setEditDialogOpen(false)}>
                <DialogTitle>Edit Applicant</DialogTitle>
                <DialogContent>
                    <TextField label="Name" name="name" value={editForm.name} onChange={handleEditChange} fullWidth margin="dense"/>
                    <TextField label="Email" name="email" value={editForm.email} onChange={handleEditChange} fullWidth margin="dense"/>
                    <TextField label="Phone" name="phone" value={editForm.phone} onChange={handleEditChange} fullWidth margin="dense"/>
                    <TextField label="Role" name="role" value={editForm.role} onChange={handleEditChange} fullWidth margin="dense"/>
                    <TextField label="Hourly Rate" name="hourlyRate" type="number" value={editForm.hourlyRate} onChange={handleEditChange} fullWidth margin="dense"/>
                    <TextField label="City" name="city" value={editForm.city} onChange={handleEditChange} fullWidth margin="dense"/>
                    <TextField label="State" name="state" value={editForm.state} onChange={handleEditChange} fullWidth margin="dense"/>
                    <TextField label="Zip" name="zip" value={editForm.zip} onChange={handleEditChange} fullWidth margin="dense"/>
                    <Typography variant="h6" sx={{ marginTop: '1rem', color: '#12c099' }}>
                        Availability
                    </Typography>
                    {Object.entries(editForm.availability || {}).map(([day, times]) => (
                        <FormControl key={day} fullWidth sx={{ marginBottom: '1rem' }}>
                            <InputLabel>{day.charAt(0).toUpperCase() + day.slice(1)}</InputLabel>
                            <Select
                                multiple
                                value={times.map(slot => `${slot.start} - ${slot.end}`)} // ✅ Convert object to string
                                onChange={(e) => handleAvailabilityChange(day, e.target.value)}
                                renderValue={(selected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {selected.map((value) => (
                                            <Chip key={value} label={value} onDelete={() => {
                                                const updatedTimes = selected.filter(t => t !== value);
                                                handleAvailabilityChange(day, updatedTimes);
                                            }} />
                                        ))}
                                    </Box>
                                )}
                            >
                                {[
                                    "8:00 AM - 10:00 AM",
                                    "9:00 AM - 11:00 AM",
                                    "10:00 AM - 12:00 PM",
                                    "12:00 PM - 2:00 PM",
                                    "2:00 PM - 4:00 PM",
                                    "4:00 PM - 6:00 PM",
                                    "6:00 PM - 8:00 PM"
                                ].map((timeSlot) => (
                                    <MenuItem key={timeSlot} value={timeSlot}>
                                        {timeSlot}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    ))}

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setEditDialogOpen(false)} color="primary">Cancel</Button>
                    <Button onClick={handleEditSubmit} color="secondary">Save</Button>
                </DialogActions>
            </Dialog>;
        </OverviewContainer>
    );
};

export default ApplicantOverview;
