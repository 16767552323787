import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import axios from 'axios';

const UserBadge = ({ firebaseUid }) => {
    const [userTitle, setUserTitle] = useState('');

    useEffect(() => {
        const fetchUserTitle = async () => {
            try {
                const response = await axios.get(`/api/employee/${firebaseUid}`);
                const employee = response.data;
                setUserTitle(employee.title);  // Assuming response contains the employee's title
            } catch (error) {
                console.error('Error fetching employee data:', error);
            }
        };

        if (firebaseUid) {
            fetchUserTitle();
        }
    }, [firebaseUid]);

    return (
        <Box
            sx={{
                padding: '0.2rem 1rem',
                backgroundColor: '#202020',
                borderRadius: '8px',
                border: '2px solid #12c099',
                color: '#12c099',
                textAlign: 'center',
                fontWeight: 'bold',
                fontSize: '1rem',
                display: 'inline-block',  // Ensures it does not take up full width
                marginTop: '0.1rem',  // Adds space below the employee name
            }}
        >
            <Typography variant="body1">{userTitle || 'Loading...'}</Typography>
        </Box>
    );
};

export default UserBadge;
