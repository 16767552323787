import React, { useEffect, useState } from 'react';
import { Typography, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import axios from 'axios';

const CustomPaper = styled(Paper)({
    padding: '2rem',
    backgroundColor: '#202020',
    color: '#fff',
    borderRadius: '12px',
    minHeight: '180px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
});

const RecurringMembersComponent = () => {
    const [recurringCount, setRecurringCount] = useState(0);

    useEffect(() => {
        const fetchRecurringCount = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_BACKEND_URL}/api/users/count/recurring`
                );
                setRecurringCount(response.data.totalRecurringMembers);
            } catch (error) {
                console.error('Error fetching recurring members count:', error);
            }
        };

        fetchRecurringCount();
    }, []);

    return (
        <CustomPaper>
            <Typography variant="h5" sx={{ marginBottom: '1rem', fontWeight: '500' }}>
                Recurring Members
            </Typography>
            <Typography variant="h3" sx={{ color: '#12c099', fontWeight: 'bold' }}>
                {recurringCount}
            </Typography>
        </CustomPaper>
    );
};

export default RecurringMembersComponent;
