import React, { useEffect, useState } from 'react';
import { Grid, Box, Typography } from '@mui/material';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import axios from 'axios';
import ProgressComponent from '../components/dashboard-components/ProgressComponent';
import NeighborhoodsComponent from '../components/dashboard-components/NeighborhoodsComponent';
import AssignedNeighborhoodsComponent from '../components/dashboard-components/AssignedNeighborhoodsComponent';
import AvailableCollaboratorsComponent from '../components/dashboard-components/AvailableCollaboratorsComponent';
import QuotasComponent from '../components/dashboard-components/QuotasComponent';
import SessionsTableComponent from '../components/dashboard-components/SessionsTableComponent';
import FooterComponent from '../components/dashboard-components/FooterComponent';
import UserBadge from '../components/UserBadge';  // Import UserBadge component

// Setting Axios base URL from environment variable
axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL;

const BookingDashboard = () => {
    const [employeeName, setEmployeeName] = useState('');
    const [employeeTitle, setEmployeeTitle] = useState(''); // State for employee title
    const [formattedDate, setFormattedDate] = useState('');
    const [neighborhoods, setNeighborhoods] = useState([]);
    const [locationDetails, setLocationDetails] = useState({ city: '', state: '', country: '' });
    const [mapCenter, setMapCenter] = useState({ lat: 37.7749, lng: -122.4194 }); // Default center: San Francisco
    const [sport, setSport] = useState(''); // State for sport

    useEffect(() => {
        const auth = getAuth();

        console.log('Setting up auth state change listener');
        // Listen for authentication state changes
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            console.log('Auth state changed:', user);
            if (user) {
                try {
                    const firebaseUid = user.uid; // Get Firebase UID from authenticated user
                    console.log('Fetching employee details for firebaseUid:', firebaseUid);

                    // Fetch the employee details including neighborhoods, city, state, and country
                    const response = await axios.get(`/api/employees/${firebaseUid}/neighborhoods`);
                    console.log('Employee data fetched:', response.data);
                    if (response.data) {
                        const { neighborhoods, city, state, country, sport: employeeSport } = response.data;
                        setNeighborhoods(neighborhoods || []);
                        setLocationDetails({ city, state, country });
                        setSport(employeeSport || 'Unknown Sport'); // Set sport from the response
                    } else {
                        console.warn('No data found for employee neighborhoods');
                    }

                    // Fetch the employee name and title separately
                    const employeeResponse = await axios.get(`/api/employee/${firebaseUid}`);
                    console.log('Employee name and title data fetched:', employeeResponse.data);
                    if (employeeResponse.data) {
                        setEmployeeName(employeeResponse.data.name || 'Employee');  // Ensure "Employee" is used as a fallback
                        setEmployeeTitle(employeeResponse.data.title || 'Title');  // Set the employee title
                    } else {
                        console.warn('No data found for employee name or title');
                        setEmployeeName('Employee');  // Fallback if no data found
                        setEmployeeTitle('Title');  // Fallback if no title found
                    }

                } catch (error) {
                    console.error('Error fetching employee data:', error);
                    setEmployeeName('Employee');  // Fallback in case of error
                    setEmployeeTitle('Title');  // Fallback in case of error
                }
            } else {
                console.warn('User is not authenticated');
                setEmployeeName('Guest');
                setEmployeeTitle('Guest');  // Set title to 'Guest' if user is not authenticated
            }
        });

        // Format today's date
        const today = new Date();
        console.log('Setting formatted date:', today.toLocaleDateString());
        setFormattedDate(today.toLocaleDateString('en-US', {
            month: 'long',
            day: 'numeric',
            year: 'numeric',
        }));

        // Cleanup subscription on unmount
        return () => {
            console.log('Cleaning up auth state listener');
            unsubscribe();
        };
    }, []);

    // Handler to update the map center based on neighborhood click
    const handleNeighborhoodClick = async (neighborhood) => {
        try {
            const { city, state, country } = locationDetails; // Extract details
            const address = `${neighborhood}, ${city}, ${state}, ${country}`; // Construct full address
            console.log('Fetching coordinates for address:', address);

            // Fetch location details from backend or Google Geocoding API
            const response = await axios.get(`/api/neighborhoods/coordinates`, {
                params: { name: neighborhood, city, state, country }
            });

            if (response.data && response.data.lat && response.data.lng) {
                const { lat, lng } = response.data;
                setMapCenter({ lat, lng });
                console.log(`Map centered to ${neighborhood}:`, { lat, lng });
            } else {
                console.error('Coordinates not found for address:', address);
            }
        } catch (error) {
            console.error('Error fetching neighborhood coordinates:', error);
        }
    };

    return (
        <Box sx={{ padding: '2rem', backgroundColor: '#1c1c1c', color: '#fff', minHeight: '100vh', display: 'flex', flexDirection: 'column', gap: '2rem' }}>
            {/* Display Employee Name and Badge in a Column Layout */}
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '0.2rem' }}>
                <Typography variant="h4" gutterBottom>{employeeName} | {formattedDate}</Typography>
                <UserBadge firebaseUid={getAuth().currentUser?.uid} />
            </Box>

            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <ProgressComponent />
                </Grid>
                <Grid item xs={12}>
                    <NeighborhoodsComponent mapCenter={mapCenter} setMapCenter={setMapCenter} />
                </Grid>
                <Grid item xs={12}>
                    {/* Pass locationDetails to AssignedNeighborhoodsComponent */}
                    <AssignedNeighborhoodsComponent neighborhoods={neighborhoods} userLocation={locationDetails} handleNeighborhoodClick={handleNeighborhoodClick} />
                </Grid>
                <Grid item xs={12}>
                    <AvailableCollaboratorsComponent />
                </Grid>
                <Grid item xs={12} md={4}>
                    <QuotasComponent quotaType="daily" address={locationDetails} sport={sport} />
                </Grid>
                <Grid item xs={12} md={4}>
                    <QuotasComponent quotaType="weekly" address={locationDetails} sport={sport} />
                </Grid>
                <Grid item xs={12} md={4}>
                    <QuotasComponent quotaType="monthly" address={locationDetails} sport={sport} />
                </Grid>
                <Grid item xs={12}>
                    <SessionsTableComponent />
                </Grid>
                <Grid item xs={12}>
                    <FooterComponent />
                </Grid>
            </Grid>
        </Box>
    );
};

export default BookingDashboard;
