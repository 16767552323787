import React, { useEffect, useState } from 'react';
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    CircularProgress,
    Typography,
    Pagination,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import opencage from 'opencage-api-client';

const StyledTableContainer = styled(TableContainer)({
    backgroundColor: '#202020',
    borderRadius: '12px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
    overflowX: 'auto',
});

const CompactTableCell = styled(TableCell)({
    padding: '16px 20px',
    fontSize: '1rem',
    color: '#fff',
});

const PaginationBox = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
});

// Fallback data for problematic zip codes
const fallbackZipCodeData = {
    '90056': { city: 'Ladera Heights', state: 'CA', country: 'USA' },
};

const Leads = () => {
    const [leads, setLeads] = useState([]);
    const [geocodedData, setGeocodedData] = useState({});
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const rowsPerPage = 25;

    useEffect(() => {
        const fetchLeads = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/leads`);
                setLeads(response.data);
                fetchGeocodedData(response.data.slice(0, rowsPerPage));
            } catch (error) {
                console.error('Error fetching leads:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchLeads();
    }, []);

    const fetchGeocodedData = async (paginatedLeads) => {
        const data = {};
        for (const lead of paginatedLeads) {
            try {
                // Check fallback first
                if (fallbackZipCodeData[lead.zipcode]) {
                    data[lead._id] = fallbackZipCodeData[lead.zipcode];
                    continue;
                }

                // Google Geocoding API
                const geoResponse = await axios.get(
                    `https://maps.googleapis.com/maps/api/geocode/json?address=${lead.zipcode}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`
                );

                if (geoResponse.data.results.length > 0) {
                    const addressComponents = geoResponse.data.results[0].address_components;

                    // Check locality, then fallback fields
                    const city =
                        addressComponents.find((comp) => comp.types.includes('locality'))?.long_name ||
                        addressComponents.find((comp) => comp.types.includes('neighborhood'))?.long_name ||
                        addressComponents.find((comp) => comp.types.includes('sublocality'))?.long_name ||
                        'N/A';
                    const state =
                        addressComponents.find((comp) => comp.types.includes('administrative_area_level_1'))?.short_name || 'N/A';
                    const country =
                        addressComponents.find((comp) => comp.types.includes('country'))?.long_name || 'N/A';

                    data[lead._id] = { city, state, country };
                } else {
                    throw new Error('No results from Google API');
                }
            } catch (googleError) {
                console.warn(`Google API failed for ${lead.zipcode}. Trying fallback API...`);

                try {
                    // Fallback to OpenCage Geocoder
                    const fallbackResponse = await opencage.geocode({
                        q: lead.zipcode,
                        key: process.env.REACT_APP_OPENCAGE_API_KEY,
                    });

                    if (fallbackResponse.results.length > 0) {
                        const firstResult = fallbackResponse.results[0];
                        const city = firstResult.components.city || firstResult.components.town || firstResult.components.village || 'N/A';
                        const state = firstResult.components.state_code || 'N/A';
                        const country = firstResult.components.country || 'N/A';

                        data[lead._id] = { city, state, country };
                    } else {
                        data[lead._id] = { city: 'N/A', state: 'N/A', country: 'N/A' };
                    }
                } catch (fallbackError) {
                    console.error(`Both APIs failed for ${lead.zipcode}:`, fallbackError);
                    data[lead._id] = { city: 'N/A', state: 'N/A', country: 'N/A' };
                }
            }
        }
        setGeocodedData(data);
    };

    const handleDelete = async (id) => {
        try {
            await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/leads/${id}`);
            setLeads(leads.filter((lead) => lead._id !== id));
        } catch (error) {
            console.error('Error deleting lead:', error);
        }
    };

    useEffect(() => {
        const paginatedLeads = leads.slice((page - 1) * rowsPerPage, page * rowsPerPage);
        fetchGeocodedData(paginatedLeads);
    }, [page, leads]);

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress color="inherit" />
            </Box>
        );
    }

    const paginatedLeads = leads.slice((page - 1) * rowsPerPage, page * rowsPerPage);

    return (
        <Box sx={{ padding: '1rem', backgroundColor: '#1c1c1c', minHeight: '100vh', color: '#fff' }}>
            <Typography variant="h4" gutterBottom>Leads</Typography>
            <StyledTableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <CompactTableCell>Name</CompactTableCell>
                            <CompactTableCell>Email</CompactTableCell>
                            <CompactTableCell>Phone</CompactTableCell>
                            <CompactTableCell>Zip Code</CompactTableCell>
                            <CompactTableCell>City</CompactTableCell>
                            <CompactTableCell>State</CompactTableCell>
                            <CompactTableCell>Country</CompactTableCell>
                            <CompactTableCell>Date Joined</CompactTableCell>
                            <CompactTableCell>Actions</CompactTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {paginatedLeads.length > 0 ? (
                            paginatedLeads.map((lead) => (
                                <TableRow key={lead._id}>
                                    <CompactTableCell>{lead.name}</CompactTableCell>
                                    <CompactTableCell>{lead.email}</CompactTableCell>
                                    <CompactTableCell>{lead.phone}</CompactTableCell>
                                    <CompactTableCell>{lead.zipcode}</CompactTableCell>
                                    <CompactTableCell>{geocodedData[lead._id]?.city || 'Loading...'}</CompactTableCell>
                                    <CompactTableCell>{geocodedData[lead._id]?.state || 'Loading...'}</CompactTableCell>
                                    <CompactTableCell>{geocodedData[lead._id]?.country || 'Loading...'}</CompactTableCell>
                                    <CompactTableCell>{new Date(lead.createdAt).toLocaleDateString()}</CompactTableCell>
                                    <CompactTableCell>
                                        <IconButton onClick={() => handleDelete(lead._id)} sx={{ color: '#ff1744' }}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </CompactTableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={9} align="center">
                                    No leads available
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </StyledTableContainer>
            <PaginationBox>
                <Pagination
                    count={Math.ceil(leads.length / rowsPerPage)}
                    page={page}
                    onChange={(event, value) => setPage(value)}
                    sx={{ button: { color: '#12c099' }, ul: { color: '#12c099' } }}
                />
            </PaginationBox>
        </Box>
    );
};

export default Leads;
