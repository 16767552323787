import React, { useState, useEffect } from 'react';
import {
    TextField,
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    Button,
    InputAdornment,
    TablePagination,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { Search as SearchIcon } from '@mui/icons-material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';

const SearchBar = styled(TextField)({
    marginBottom: '20px',
    width: '100%',
    backgroundColor: '#202020',
    borderRadius: '8px',
    color: '#fff',
    '& .MuiInputBase-root': {
        color: '#fff',
        fontSize: '1rem',
    },
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#12c099',
    },
    '& .MuiInputLabel-root': {
        color: '#12c099',
        fontSize: '1rem',
    },
});

const StyledTableContainer = styled(TableContainer)({
    backgroundColor: '#202020',
    borderRadius: '12px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
});

const CompactTableCell = styled(TableCell)({
    padding: '16px 20px',
    fontSize: '1rem',
    '@media (max-width: 768px)': {
        display: 'block',
        width: '100%',
        padding: '12px 0',
        fontSize: '0.9rem',
        textAlign: 'left',
    },
    color: '#fff',
});

const CompactTableRow = styled(TableRow)({
    '@media (max-width: 768px)': {
        display: 'block',
        width: '100%',
        marginBottom: '20px',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
        borderRadius: '8px',
        backgroundColor: '#202020',
        overflow: 'hidden',
    },
});

const PaginationBox = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
    '@media (max-width: 768px)': {
        marginTop: '10px',
    },
});

const SessionsContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    padding: '1rem',
    backgroundColor: '#1c1c1c',
    minHeight: '100vh',
    '@media (max-width: 768px)': {
        padding: '0.5rem',
    },
});

const HeaderContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
    flexWrap: 'wrap',
    '@media (max-width: 768px)': {
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
});

const Title = styled(Typography)({
    fontSize: '1.8rem',
    color: '#fff',
    fontWeight: 'bold',
    '@media (max-width: 768px)': {
        fontSize: '1.5rem',
    },
});

const Footer = styled(Box)({
    padding: '1rem',
    textAlign: 'center',
    color: '#fff',
    backgroundColor: 'transparent',
    marginTop: 'auto',
});

const Sessions = () => {
    const [sessions, setSessions] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(() => {
        const fetchSessions = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/sessions`);
                setSessions(response.data);
            } catch (error) {
                console.error('Error fetching sessions data:', error);
            }
        };
        fetchSessions();
    }, []);

    const filteredSessions = sessions.filter((session) => {
        const matchesSearch = session.name.toLowerCase().includes(searchQuery.toLowerCase());
        const matchesDateRange =
            (!startDate || new Date(session.eventDate) >= startDate) &&
            (!endDate || new Date(session.eventDate) <= endDate);
        return matchesSearch && matchesDateRange;
    });

    const paginatedSessions = filteredSessions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    return (
        <SessionsContainer>
            <HeaderContainer>
                <Title variant="h4" gutterBottom>Sessions</Title>
                <SearchBar
                    variant="outlined"
                    label="Search Sessions"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon sx={{ color: '#fff' }} />
                            </InputAdornment>
                        ),
                    }}
                />
            </HeaderContainer>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: '1rem',
                alignItems: 'center',
                marginBottom: '20px',
            }}>
                <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    placeholderText="Start Date"
                    customInput={<TextField variant="outlined" placeholder="Start Date" sx={{ backgroundColor: '#282828', color: '#fff', width: '100%' }} />}
                />
                <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    placeholderText="End Date"
                    minDate={startDate}
                    customInput={<TextField variant="outlined" placeholder="End Date" sx={{ backgroundColor: '#282828', color: '#fff', width: '100%' }} />}
                />
            </Box>
            <StyledTableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <CompactTableCell>Session</CompactTableCell>
                            <CompactTableCell>Host</CompactTableCell>
                            <CompactTableCell>Date</CompactTableCell>
                            <CompactTableCell align="center">Actions</CompactTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {paginatedSessions.map((session) => (
                            <CompactTableRow key={session._id}>
                                <CompactTableCell>{session.name}</CompactTableCell>
                                <CompactTableCell>{session.hostName}</CompactTableCell>
                                <CompactTableCell>{new Date(session.eventDate).toLocaleDateString()}</CompactTableCell>
                                <CompactTableCell align="center">
                                    <Button
                                        variant="contained"
                                        sx={{
                                            backgroundColor: '#12c099',
                                            color: '#fff',
                                            fontSize: '0.75rem', // Slightly smaller text
                                            padding: '4px 8px',  // Reduced padding
                                        }}
                                        component={Link}
                                        to={`/session/${session._id}`} // Pass the session ID in the URL
                                    >
                                        View Session
                                    </Button>
                                </CompactTableCell>
                            </CompactTableRow>
                        ))}
                    </TableBody>
                </Table>
                <TablePagination
                    component="div"
                    count={filteredSessions.length}
                    page={page}
                    onPageChange={(event, newPage) => setPage(newPage)}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={(event) => setRowsPerPage(parseInt(event.target.value, 10))}
                    sx={{ backgroundColor: '#202020', color: '#fff' }}
                />
            </StyledTableContainer>
            <Footer>
                <Typography variant="body2">
                    &copy; {new Date().getFullYear()} Mars Reel Media Corp. All rights reserved.
                </Typography>
            </Footer>
        </SessionsContainer>
    );
};

export default Sessions;
