import React, { useEffect, useState } from 'react';
import { Box, Typography, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_BACKEND_URL || 'https://api.admin.marsreel.com';

const CustomPaper = styled(Paper)({
    padding: '2rem',
    backgroundColor: '#202020',
    color: '#fff',
    borderRadius: '12px',
    minHeight: '180px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
});

const FlexBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    flexShrink: 0,
});

const ActiveLocationsComponent = () => {
    const [activeLocationsCount, setActiveLocationsCount] = useState(0);

    useEffect(() => {
        const fetchActiveLocations = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/api/active-locations/count`);
                setActiveLocationsCount(response.data.count);
            } catch (error) {
                console.error('Error fetching active locations:', error);
            }
        };

        fetchActiveLocations();
    }, []);

    return (
        <CustomPaper>
            <FlexBox>
                <Typography variant="h5" sx={{ marginBottom: '1rem', fontWeight: '500' }}>
                    Active Locations
                </Typography>
                <Typography variant="h3" sx={{ color: '#12c099', fontWeight: 'bold' }}>
                    {activeLocationsCount}
                </Typography>
            </FlexBox>
        </CustomPaper>
    );
};

export default ActiveLocationsComponent;
